import * as React from 'react';
import { Fragment } from 'react';
import { graphql } from 'gatsby';
import { Link } from 'gatsby';
import Layout from '../components/layout';
import Seo from '../components/seo';
import { Tab } from '@headlessui/react';
import FileIcon from '../icons/buttons/pixfort-icon-file-content-1.svg';
import ShieldIcon from '../icons/buttons/pixfort-icon-shield-lock-1.svg';
import BookmarkIcon from '../icons/buttons/pixfort-icon-bookmark-2.svg';
import EnglishIcon from '../icons/flags/english-flag.svg';
import FrenchIcon from '../icons/flags/french-flag.svg';

function classNames(...classes) {
	return classes.filter(Boolean).join(' ');
}

const Legal = ({ data, location }) => {
	return (
		<Layout location={location} title='Terms of use'>
			<Seo title='Terms of use' />
			<div className=''>
				<main className='mx-auto mt-24 max-w-3xl px-4 sm:px-6 lg:px-8 md:mt-32'>
					<div className='flex flex-col md:flex-row mb-4 font-semibold'>
						<div className='py-2 md:py-4 mr-2'>
							<Link
								className='justify-center md:justify-start stroke-gray-400 hover:stroke-gray-600 dark:hover:stroke-white bg-transparent text-gray-400 hover:bg-gray-100 hover:text-gray-600 dark:hover:text-white dark:hover:bg-white/10 transition ease-in-out duration-100 px-3 py-2 border border-transparent rounded-md text-sm align-middle h-full flex items-center self-auto'
								to='/terms'
							>
								<FileIcon className='inline-block mr-2' />
								<span className='inline-flex align-middle'> Terms of use</span>
							</Link>
						</div>
						<div className='py-2 md:py-4 mr-2'>
							<Link
								to='/privacy-policy'
								className='justify-center md:justify-start stroke-gray-400 hover:stroke-gray-600 dark:hover:stroke-white bg-transparent text-gray-400 hover:bg-gray-100 hover:text-gray-600 dark:hover:text-white dark:hover:bg-white/10 transition ease-in-out duration-100 px-3 py-2 border border-transparent rounded-md text-sm align-middle h-full flex items-center self-auto'
							>
								<ShieldIcon className='inline-block mr-2' />
								<span className='inline-flex align-middle'>Privacy Policy</span>
							</Link>
						</div>
						<div className='py-2 md:py-4'>
							<Link
								to='/legal'
								className='justify-center md:justify-start stroke-gray-600 hover:stroke-gray-900 dark:stroke-white text-gray-600 bg-gray-100  hover:text-gray-900 dark:text-white dark:bg-white/10 transition ease-in-out duration-100 px-3 py-2 border border-transparent rounded-md text-sm align-middle h-full flex items-center self-auto'
							>
								<BookmarkIcon className='inline-block mr-2' />
								<span className='inline-flex align-middle'>Legal</span>
							</Link>
						</div>
					</div>

					<div className='text-left lg:text-left text-gray-500 dark:text-gray-400 prose dark:prose-invert max-w-none'>
						<h1 className='text-4xl font-bold tracking-tight text-gray-900 dark:text-white sm:text-5xl md:text-6xl mb-4'>
							<span className='block xl:inline'>Legal</span>
						</h1>
						<Tab.Group>
							<Tab.List className='flex flex-col md:flex-row mb-4 mt-8 font-semibold text-gray-400 dark:text-gray-400'>
								<Tab className={({ selected }) => classNames('text-left px-3 py-2 sm:mr-4 rounded-md ', selected ? 'bg-gray-100 dark:bg-white/10 hover:bg-gray-200 dark:hover:bg-white/20 text-gray-700 dark:text-gray-200' : 'hover:bg-gray-100 dark:hover:bg-white/10')}>
									<FrenchIcon className='inline-block mr-2' />
									French
								</Tab>
								<Tab className={({ selected }) => classNames('text-left px-3 py-2 sm:mr-4 rounded-md', selected ? 'bg-gray-100 dark:bg-white/10 hover:bg-gray-200 dark:hover:bg-white/20 text-gray-700 dark:text-gray-200' : 'hover:bg-gray-100 dark:hover:bg-white/10')}>
									<EnglishIcon className='inline-block mr-2' />
									English
								</Tab>
							</Tab.List>
							<Tab.Panels>
								<Tab.Panel>
									<h2 className=''>Définitions</h2>
									<ol type='a'>
										<li>
											<strong>Client :</strong> tout professionnel ou personne physique capable au sens des articles 1123 et suivants du Code civil, ou personne morale, qui visite le Site objet des présentes conditions générales.
										</li>
										<li>
											<strong>Prestations et Services :</strong> tout type de services ou de produits fournis par pixfort.
										</li>
										<li>
											<strong>Contenu :</strong> Ensemble des éléments constituants l'information présente sur le Site, notamment textes - images - vidéos.
										</li>
										<li>
											<strong>Informations clients :</strong> Ci après dénommé « Information (s) » qui correspondent à l'ensemble des données personnelles susceptibles d'être détenues par <a href='https://pixfort.com'>pixfort.com</a> pour la gestion de votre compte, de la
											gestion de la relation client et à des fins d'analyses et de statistiques.
										</li>
										<li>
											<strong>Utilisateur :</strong> Internaute se connectant, utilisant le site susnommé.
										</li>
										<li>
											<strong>Informations personnelles :</strong> « Les informations qui permettent, sous quelque forme que ce soit, directement ou non, l'identification des personnes physiques auxquelles elles s'appliquent » (article 4 de la loi n° 78-17 du 6 janvier 1978).
										</li>

										<p>Les termes « données à caractère personnel », « personne concernée », « sous traitant » et « données sensibles » ont le sens défini par le Règlement Général sur la Protection des Données (RGPD : n° 2016-679)</p>
									</ol>
									<h2>1. Présentation du site internet.</h2>
									<p>
										En vertu de l'article 6 de la loi n° 2004-575 du 21 juin 2004 pour la confiance dans l'économie numérique, il est précisé aux utilisateurs du site internet <a href='https://pixfort.com'>pixfort.com</a> l'identité des différents intervenants dans le cadre de sa
										réalisation et de son suivi:
									</p>
									<p>
										<strong>Propriétaire</strong> : SARL pixfort, Capital social de 1000€, numéro de TVA: FR08913146585, adresse du siège social de l'entreprise : 2 Rue charles baudelaire, 69002, Lyon.
										<br />
										<strong>Responsable publication</strong> : pixfort SARL - support@pixfort.com
										<br />
										<strong>Webmaster</strong> : pixfort SARL - support@pixfort.com
										<br />
										<strong>Hébergeur</strong> : DigitalOcean, LLC - 101 6th Ave, New York, NY
										<br />
										<strong>Délégué à la protection des données</strong> : pixfort SARL - support@pixfort.com
										<br />
									</p>
									<h2>2. Conditions générales d'utilisation du site et des services proposés.</h2>
									<p>
										Le Site constitue une œuvre de l'esprit protégée par les dispositions du Code de la Propriété Intellectuelle et des Réglementations Internationales applicables. Le Client ne peut en aucune manière réutiliser, céder ou exploiter pour son propre compte tout ou
										partie des éléments ou travaux du Site.
									</p>
									<p>
										L'utilisation du site <a href='https://www.pixfort.com'>pixfort.com</a> implique l'acceptation pleine et entière des conditions générales d'utilisation ci-après décrites. Ces conditions d'utilisation sont susceptibles d'être modifiées ou complétées à tout
										moment, les utilisateurs du site <a href='https://www.pixfort.com/'>pixfort.com</a> sont donc invités à les consulter de manière régulière.
									</p>
									<p>
										Ce site internet est normalement accessible à tout moment aux utilisateurs. Une interruption pour raison de maintenance technique peut être toutefois décidée par <a href='https://www.pixfort.com/'>pixfort.com</a>, qui s'efforcera alors de communiquer
										préalablement aux utilisateurs les dates et heures de l'intervention. Le site web <a href='https://www.pixfort.com/'>pixfort.com</a> est mis à jour régulièrement par <a href='https://www.pixfort.com/'>pixfort.com</a> responsable. De la même façon, les mentions
										légales peuvent être modifiées à tout moment : elles s'imposent néanmoins à l'utilisateur qui est invité à s'y référer le plus souvent possible afin d'en prendre connaissance.
									</p>
									<h2>3. Description des services fournis.</h2>
									<p>
										Le site internet <a href='https://www.pixfort.com/'>pixfort.com</a> a pour objet de fournir une information concernant l'ensemble des activités de la société.
										<a href='https://www.pixfort.com/'>pixfort.com</a> s'efforce de fournir sur le site <a href='https://www.pixfort.com/'>pixfort.com</a> des informations aussi précises que possible. Toutefois, il ne pourra être tenu responsable des oublis, des inexactitudes et
										des carences dans la mise à jour, qu'elles soient de son fait ou du fait des tiers partenaires qui lui fournissent ces informations.
									</p>
									<p>
										Toutes les informations indiquées sur le site <a href='https://www.pixfort.com/'>pixfort.com</a> sont données à titre indicatif, et sont susceptibles d'évoluer. Par ailleurs, les renseignements figurant sur le site{' '}
										<a href='https://www.pixfort.com/'>pixfort.com</a> ne sont pas exhaustifs. Ils sont donnés sous réserve de modifications ayant été apportées depuis leur mise en ligne.
									</p>
									<h2>4. Limitations contractuelles sur les données techniques.</h2>
									<p>
										Le site utilise la technologie JavaScript. Le site Internet ne pourra être tenu responsable de dommages matériels liés à l'utilisation du site. De plus, l'utilisateur du site s'engage à accéder au site en utilisant un matériel récent, ne contenant pas de virus
										et avec un navigateur de dernière génération mis-à-jour.
									</p>
									<p>
										L'objectif est d'apporter une prestation qui assure le meilleur taux d'accessibilité. L'hébergeur assure la continuité de son service 24 Heures sur 24, tous les jours de l'année. Il se réserve néanmoins la possibilité d'interrompre le service d'hébergement
										pour les durées les plus courtes possibles notamment à des fins de maintenance, d'amélioration de ses infrastructures, de défaillance de ses infrastructures ou si les Prestations et Services génèrent un trafic réputé anormal.
									</p>
									<p>
										<a href='https://www.pixfort.com/'>pixfort.com</a> et l'hébergeur ne pourront être tenus responsables en cas de dysfonctionnement du réseau Internet, des lignes téléphoniques ou du matériel informatique et de téléphonie lié notamment à l'encombrement du réseau
										empêchant l'accès au serveur.
									</p>
									<h2>5. Propriété intellectuelle et contrefaçons.</h2>
									<p>
										<a href='https://www.pixfort.com/'>pixfort.com</a> est propriétaire des droits de propriété intellectuelle et détient les droits d'usage sur tous les éléments accessibles sur le site internet, notamment les textes, images, graphismes, logos, vidéos, icônes et
										sons. Toute reproduction, représentation, modification, publication, adaptation de tout ou partie des éléments du site, quel que soit le moyen ou le procédé utilisé, est interdite, sauf autorisation écrite préalable de :{' '}
										<a href='https://www.pixfort.com/'>pixfort.com</a>.
									</p>
									<p>Toute exploitation non autorisée du site ou de l'un quelconque des éléments qu'il contient sera considérée comme constitutive d'une contrefaçon et poursuivie conformément aux dispositions des articles L.335-2 et suivants du Code de Propriété Intellectuelle.</p>
									<h2>6. Limitations de responsabilité.</h2>
									<p>
										<a href='https://www.pixfort.com/'>pixfort.com</a> agit en tant qu'éditeur du site. <a href='https://www.pixfort.com/'>pixfort.com</a> est responsable de la qualité et de la véracité du Contenu qu'il publie.{' '}
									</p>
									<p>
										<a href='https://www.pixfort.com/'>pixfort.com</a> ne pourra être tenu responsable des dommages directs et indirects causés au matériel de l'utilisateur, lors de l'accès au site internet <a href='https://www.pixfort.com/'>pixfort.com</a>, et résultant soit de
										l'utilisation d'un matériel ne répondant pas aux spécifications indiquées au point 4, soit de l'apparition d'un bug ou d'une incompatibilité.
									</p>
									<p>
										<a href='https://www.pixfort.com/'>pixfort.com</a> ne pourra également être tenu responsable des dommages indirects (tels par exemple qu'une perte de marché ou perte d'une chance) consécutifs à l'utilisation du site{' '}
										<a href='https://www.pixfort.com/'>pixfort.com</a>. Des espaces interactifs (possibilité de poser des questions dans l'espace contact) sont à la disposition des utilisateurs. <a href='https://www.pixfort.com/'>pixfort.com</a> se réserve le droit de supprimer,
										sans mise en demeure préalable, tout contenu déposé dans cet espace qui contreviendrait à la législation applicable en France, en particulier aux dispositions relatives à la protection des données. Le cas échéant,{' '}
										<a href='https://www.pixfort.com/'>pixfort.com</a> se réserve également la possibilité de mettre en cause la responsabilité civile et/ou pénale de l'utilisateur, notamment en cas de message à caractère raciste, injurieux, diffamant, ou pornographique, quel que
										soit le support utilisé (texte, photographie …).
									</p>
									<h2>7. Gestion des données personnelles.</h2>
									<p>
										Le Client est informé des réglementations concernant la communication marketing, la loi du 21 Juin 2014 pour la confiance dans l'Economie Numérique, la Loi Informatique et Liberté du 06 Août 2004 ainsi que du Règlement Général sur la Protection des Données
										(RGPD : n° 2016-679).{' '}
									</p>
									<h3>7.1 Responsables de la collecte des données personnelles</h3>
									<p>Pour les Données Personnelles collectées dans le cadre de la création du compte personnel de l'Utilisateur et de sa navigation sur le Site, le responsable du traitement des Données Personnelles est : pixfort SARL - support@pixfort.com.</p>
									<p>
										En tant que responsable du traitement des données qu'il collecte, <a href='https://www.pixfort.com/'>pixfort.com</a> s'engage à respecter le cadre des dispositions légales en vigueur. Il lui appartient notamment au Client d'établir les finalités de ses
										traitements de données, de fournir à ses prospects et clients, à partir de la collecte de leurs consentements, une information complète sur le traitement de leurs données personnelles et de maintenir un registre des traitements conforme à la réalité. Chaque
										fois que <a href='https://www.pixfort.com/'>pixfort.com</a> traite des Données Personnelles, <a href='https://www.pixfort.com/'>pixfort.com</a> prend toutes les mesures raisonnables pour s'assurer de l'exactitude et de la pertinence des Données Personnelles au
										regard des finalités pour lesquelles <a href='https://www.pixfort.com/'>pixfort.com</a> les traite.
									</p>
									<h3>7.2 Finalité des données collectées</h3>
									<p>
										<a href='https://www.pixfort.com/'>pixfort.com</a> est susceptible de traiter tout ou partie des données :{' '}
									</p>
									<ul>
										<li>pour permettre la navigation sur le Site et la gestion et la traçabilité des prestations et services commandés par l'utilisateur : données de connexion et d'utilisation du Site, facturation, historique des commandes, etc. </li>

										<li>pour prévenir et lutter contre la fraude informatique (spamming, hacking…) : matériel informatique utilisé pour la navigation, l'adresse IP, le mot de passe (hashé) </li>

										<li>pour améliorer la navigation sur le Site : données de connexion et d'utilisation </li>

										<li>
											pour mener des enquêtes de satisfaction facultatives sur <a href='https://www.pixfort.com/'>pixfort.com</a> : adresse email{' '}
										</li>
										<li>pour mener des campagnes de communication (sms, mail) : numéro de téléphone, adresse email</li>
									</ul>
									<p>
										<a href='https://www.pixfort.com/'>pixfort.com</a> ne commercialise pas vos données personnelles qui sont donc uniquement utilisées par nécessité ou à des fins statistiques et d'analyses.
									</p>
									<h3>7.3 Droit d'accès, de rectification et d'opposition</h3>
									<p>
										Conformément à la réglementation européenne en vigueur, les Utilisateurs de <a href='https://www.pixfort.com/'>pixfort.com</a> disposent des droits suivants :{' '}
									</p>
									<ul>
										<li>
											droit d'accès (article 15 RGPD) et de rectification (article 16 RGPD), de mise à jour, de complétude des données des Utilisateurs droit de verrouillage ou d'effacement des données des Utilisateurs à caractère personnel (article 17 du RGPD), lorsqu'elles
											sont inexactes, incomplètes, équivoques, périmées, ou dont la collecte, l'utilisation, la communication ou la conservation est interdite{' '}
										</li>

										<li>droit de retirer à tout moment un consentement (article 13-2c RGPD) </li>

										<li>droit à la limitation du traitement des données des Utilisateurs (article 18 RGPD) </li>

										<li>droit d'opposition au traitement des données des Utilisateurs (article 21 RGPD) </li>

										<li>droit à la portabilité des données que les Utilisateurs auront fournies, lorsque ces données font l'objet de traitements automatisés fondés sur leur consentement ou sur un contrat (article 20 RGPD) </li>

										<li>
											droit de définir le sort des données des Utilisateurs après leur mort et de choisir à qui <a href='https://www.pixfort.com/'>pixfort.com</a> devra communiquer (ou non) ses données à un tiers qu'ils aura préalablement désigné
										</li>
									</ul>
									<p>
										Dès que <a href='https://www.pixfort.com/'>pixfort.com</a> a connaissance du décès d'un Utilisateur et à défaut d'instructions de sa part, <a href='https://www.pixfort.com/'>pixfort.com</a> s'engage à détruire ses données, sauf si leur conservation s'avère
										nécessaire à des fins probatoires ou pour répondre à une obligation légale.
									</p>
									<p>
										Si l'Utilisateur souhaite savoir comment <a href='https://www.pixfort.com/'>pixfort.com</a> utilise ses Données Personnelles, demander à les rectifier ou s'oppose à leur traitement, l'Utilisateur peut contacter{' '}
										<a href='https://www.pixfort.com/'>pixfort.com</a> par écrit à l'adresse suivante : support@pixfort.com
									</p>
									<p>
										Dans ce cas, l'Utilisateur doit indiquer les Données Personnelles qu'il souhaiterait que <a href='https://www.pixfort.com/'>pixfort.com</a> corrige, mette à jour ou supprime, en s'identifiant précisément avec une copie d'une pièce d'identité (carte d'identité
										ou passeport).{' '}
									</p>
									<p>
										Les demandes de suppression de Données Personnelles seront soumises aux obligations qui sont imposées à <a href='https://www.pixfort.com/'>pixfort.com</a> par la loi, notamment en matière de conservation ou d'archivage des documents.
									</p>
									<h3>7.4 Non-communication des données personnelles</h3>
									<p>
										<a href='https://www.pixfort.com/'>pixfort.com</a> s'interdit de traiter, héberger ou transférer les Informations collectées sur ses Clients vers un pays reconnu comme « non adéquat » par la Commission européenne sans en informer préalablement le client. Pour
										autant, <a href='https://www.pixfort.com/'>pixfort.com</a> reste libre du choix de ses sous-traitants techniques et commerciaux à la condition qu'il présentent les garanties suffisantes au regard des exigences du Règlement Général sur la Protection des Données
										(RGPD : n° 2016-679).
									</p>
									<p>
										<a href='https://www.pixfort.com/'>pixfort.com</a> s'engage à prendre toutes les précautions nécessaires afin de préserver la sécurité des Informations et notamment qu'elles ne soient pas communiquées à des personnes non autorisées. Cependant, si un incident
										impactant l'intégrité ou la confidentialité des Informations du Client est portée à la connaissance de <a href='https://www.pixfort.com/'>pixfort.com</a>, celle-ci devra dans les meilleurs délais informer le Client et lui communiquer les mesures de corrections
										prises. Par ailleurs <a href='https://www.pixfort.com/'>pixfort.com</a> ne collecte aucune « données sensibles ».
									</p>
									<p>
										Les Données Personnelles de l'Utilisateur peuvent être traitées par des filiales de <a href='https://www.pixfort.com/'>pixfort.com</a> et des sous-traitants (prestataires de services), exclusivement afin de réaliser les finalités de la présente politique.
									</p>
									<p>
										Dans la limite de leurs attributions respectives et pour les finalités rappelées ci-dessus, les principales personnes susceptibles d'avoir accès aux données des Utilisateurs de <a href='https://www.pixfort.com/'>pixfort.com</a> sont principalement les agents
										de notre service client.
									</p>
									<div ng-bind-html='rgpdHTML'>
										<h3>7.5 Types de données collectées</h3>
										<p>
											Concernant les utilisateurs d'un Site <a href='https://www.pixfort.com/'>pixfort.com</a>, nous collectons les données qui sont indispensables au fonctionnement du service&nbsp;, et qui seront conservées pendant une période maximale de 2 ans après la fin de
											la relation contractuelle.
											<br />
										</p>
									</div>
									<h2>8. Notification d'incident</h2>
									<p>
										Quels que soient les efforts fournis, aucune méthode de transmission sur Internet et aucune méthode de stockage électronique n'est complètement sûre. Nous ne pouvons en conséquence pas garantir une sécurité absolue. Si nous prenions connaissance d'une brèche
										de la sécurité, nous avertirions les utilisateurs concernés afin qu'ils puissent prendre les mesures appropriées. Nos procédures de notification d'incident tiennent compte de nos obligations légales, qu'elles se situent au niveau national ou européen. Nous
										nous engageons à informer pleinement nos clients de toutes les questions relevant de la sécurité de leur compte et à leur fournir toutes les informations nécessaires pour les aider à respecter leurs propres obligations réglementaires en matière de reporting.
									</p>
									<p>
										Aucune information personnelle de l'utilisateur du site <a href='https://www.pixfort.com/'>pixfort.com</a> n'est publiée à l'insu de l'utilisateur, échangée, transférée, cédée ou vendue sur un support quelconque à des tiers. Seule l'hypothèse du rachat de{' '}
										<a href='https://www.pixfort.com/'>pixfort.com</a> et de ses droits permettrait la transmission des dites informations à l'éventuel acquéreur qui serait à son tour tenu de la même obligation de conservation et de modification des données vis à vis de
										l'utilisateur du site <a href='https://www.pixfort.com/'>pixfort.com</a>.
									</p>
									<h3>Sécurité</h3>
									<p>
										Pour assurer la sécurité et la confidentialité des Données Personnelles et des Données Personnelles de Santé, <a href='https://www.pixfort.com/'>pixfort.com</a> utilise des réseaux protégés par des dispositifs standards tels que par pare-feu, la
										pseudonymisation, l'encryption et mot de passe.{' '}
									</p>
									<p>
										Lors du traitement des Données Personnelles, <a href='https://www.pixfort.com/'>pixfort.com</a> prend toutes les mesures raisonnables visant à les protéger contre toute perte, utilisation détournée, accès non autorisé, divulgation, altération ou destruction.
									</p>
									<h2>9. Liens hypertextes « cookies » et balises (“tags”) internet</h2>
									<p>
										Le site <a href='https://www.pixfort.com/'>pixfort.com</a> contient un certain nombre de liens hypertextes vers d'autres sites, mis en place avec l'autorisation de <a href='https://www.pixfort.com/'>pixfort.com</a>. Cependant,{' '}
										<a href='https://www.pixfort.com/'>pixfort.com</a> n'a pas la possibilité de vérifier le contenu des sites ainsi visités, et n'assumera en conséquence aucune responsabilité de ce fait.
									</p>
									Sauf si vous décidez de désactiver les cookies, vous acceptez que le site puisse les utiliser. Vous pouvez à tout moment désactiver ces cookies et ce gratuitement à partir des possibilités de désactivation qui vous sont offertes et rappelées ci-après, sachant que
									cela peut réduire ou empêcher l'accessibilité à tout ou partie des Services proposés par le site.
									<p></p>
									<h3>9.1. « COOKIES »</h3>
									<p>
										Un « cookie » est un petit fichier d'information envoyé sur le navigateur de l'Utilisateur et enregistré au sein du terminal de l'Utilisateur (ex : ordinateur, smartphone), (ci-après « Cookies »). Ce fichier comprend des informations telles que le nom de
										domaine de l'Utilisateur, le fournisseur d'accès Internet de l'Utilisateur, le système d'exploitation de l'Utilisateur, ainsi que la date et l'heure d'accès. Les Cookies ne risquent en aucun cas d'endommager le terminal de l'Utilisateur.
									</p>
									<p>
										<a href='https://www.pixfort.com/'>pixfort.com</a> est susceptible de traiter les informations de l'Utilisateur concernant sa visite du Site, telles que les pages consultées, les recherches effectuées. Ces informations permettent à{' '}
										<a href='https://www.pixfort.com/'>pixfort.com</a> d'améliorer le contenu du Site, de la navigation de l'Utilisateur.
									</p>
									<p>
										Les Cookies facilitant la navigation et/ou la fourniture des services proposés par le Site, l'Utilisateur peut configurer son navigateur pour qu'il lui permette de décider s'il souhaite ou non les accepter de manière à ce que des Cookies soient enregistrés
										dans le terminal ou, au contraire, qu'ils soient rejetés, soit systématiquement, soit selon leur émetteur. L'Utilisateur peut également configurer son logiciel de navigation de manière à ce que l'acceptation ou le refus des Cookies lui soient proposés
										ponctuellement, avant qu'un Cookie soit susceptible d'être enregistré dans son terminal. <a href='https://www.pixfort.com/'>pixfort.com</a> informe l'Utilisateur que, dans ce cas, il se peut que les fonctionnalités de son logiciel de navigation ne soient pas
										toutes disponibles.
									</p>
									<p>
										Si l'Utilisateur refuse l'enregistrement de Cookies dans son terminal ou son navigateur, ou si l'Utilisateur supprime ceux qui y sont enregistrés, l'Utilisateur est informé que sa navigation et son expérience sur le Site peuvent être limitées. Cela pourrait
										également être le cas lorsque <a href='https://www.pixfort.com/'>pixfort.com</a> ou l'un de ses prestataires ne peut pas reconnaître, à des fins de compatibilité technique, le type de navigateur utilisé par le terminal, les paramètres de langue et d'affichage
										ou le pays depuis lequel le terminal semble connecté à Internet.
									</p>
									<p>
										Le cas échéant, <a href='https://www.pixfort.com/'>pixfort.com</a> décline toute responsabilité pour les conséquences liées au fonctionnement dégradé du Site et des services éventuellement proposés par <a href='https://www.pixfort.com/'>pixfort.com</a>,
										résultant (i) du refus de Cookies par l'Utilisateur (ii) de l'impossibilité pour <a href='https://www.pixfort.com/'>pixfort.com</a> d'enregistrer ou de consulter les Cookies nécessaires à leur fonctionnement du fait du choix de l'Utilisateur. Pour la gestion
										des Cookies et des choix de l'Utilisateur, la configuration de chaque navigateur est différente. Elle est décrite dans le menu d'aide du navigateur, qui permettra de savoir de quelle manière l'Utilisateur peut modifier ses souhaits en matière de Cookies.
									</p>
									<p>
										À tout moment, l'Utilisateur peut faire le choix d'exprimer et de modifier ses souhaits en matière de Cookies. <a href='https://www.pixfort.com/'>pixfort.com</a> pourra en outre faire appel aux services de prestataires externes pour l'aider à recueillir et
										traiter les informations décrites dans cette section.
									</p>
									{/* <p>
										Enfin, en cliquant sur les icônes dédiées aux réseaux sociaux Twitter, Facebook, Linkedin et pixfort Plus figurant sur le Site de <a href='https://www.pixfort.com/'>pixfort.com</a> ou dans son application mobile et si l'Utilisateur a accepté le dépôt de
										cookies en poursuivant sa navigation sur le Site Internet ou l'application mobile de <a href='https://www.pixfort.com/'>pixfort.com</a>, Twitter, Facebook, Linkedin et pixfort Plus peuvent également déposer des cookies sur vos terminaux (ordinateur, tablette,
										téléphone portable).
									</p>
									<p>
										Ces types de cookies ne sont déposés sur vos terminaux qu'à condition que vous y consentiez, en continuant votre navigation sur le Site Internet ou l'application mobile de <a href='https://www.pixfort.com/'>pixfort.com</a>. À tout moment, l'Utilisateur peut
										néanmoins revenir sur son consentement à ce que <a href='https://www.pixfort.com/'>pixfort.com</a> dépose ce type de cookies.
									</p> */}
									<h3>Article 9.2. BALISES (“TAGS”) INTERNET</h3>
									<p>
										<a href='https://www.pixfort.com/'>pixfort.com</a> peut employer occasionnellement des balises Internet (également appelées « tags », ou balises d'action, GIF à un pixel, GIF transparents, GIF invisibles et GIF un à un) et les déployer par l'intermédiaire d'un
										partenaire spécialiste d'analyses Web susceptible de se trouver (et donc de stocker les informations correspondantes, y compris l'adresse IP de l'Utilisateur) dans un pays étranger.
									</p>
									<p>Ces balises sont placées à la fois dans les publicités en ligne permettant aux internautes d'accéder au Site, et sur les différentes pages de celui-ci.</p>
									<p>
										Cette technologie permet à <a href='https://www.pixfort.com/'>pixfort.com</a> d'évaluer les réponses des visiteurs face au Site et l'efficacité de ses actions (par exemple, le nombre de fois où une page est ouverte et les informations consultées), ainsi que
										l'utilisation de ce Site par l'Utilisateur.{' '}
									</p>
									<p>
										Le prestataire externe pourra éventuellement recueillir des informations sur les visiteurs du Site et d'autres sites Internet grâce à ces balises, constituer des rapports sur l'activité du Site à l'attention de{' '}
										<a href='https://www.pixfort.com/'>pixfort.com</a>, et fournir d'autres services relatifs à l'utilisation de celui-ci et d'Internet.
									</p>
									<p></p>
									<h2>10. Droit applicable et attribution de juridiction.</h2>
									<p>
										Tout litige en relation avec l'utilisation du site <a href='https://www.pixfort.com/'>pixfort.com</a> est soumis au droit français.
									</p>
								</Tab.Panel>
								<Tab.Panel>
								For your convenience, we have provided a translation of this page below. This translation is for informational purposes only, and the definitive version of this page is the French version.
									<h2 className=''>Definitions</h2>
									<ol type='a'>
										<li>
											<strong>Customer :</strong> any professional or natural person capable within the meaning of articles 1123 and following of the Civil Code, or legal person, who visits the Site subject to these general conditions.
										</li>
										<li>
											<strong>Services:</strong> any type of service or product provided by pixfort.
										</li>
										<li>
											<strong>Content:</strong> All the constituent elements of the information present on the Site, in particular texts - images - videos.
										</li>
										<li>
											<strong>Customer Information:</strong> Hereinafter referred to as "Information" which correspond to all the personal data likely to be held by <a href='https://pixfort.com'>pixfort. com</a> for managing your account, customer relationship management and
											for analytical and statistical purposes.
										</li>
										<li>
											<strong>User:</strong> Internet user connecting, using the aforementioned site.
										</li>
										<li>
											<strong>Personal information:</strong> “Information which allows, in any form whatsoever, directly or indirectly, the identification of the natural persons to whom it applies” (article 4 of law n° 78-17 of January 6, 1978).
										</li>

										<p>The terms “personal data”, “person concerned”, “subcontractor” and “sensitive data” have the meaning defined by the General Data Protection Regulations (GDPR: n° 2016-679)</p>
									</ol>
									<h2>1. Presentation of the website.</h2>
									<p>
										Under Article 6 of Law No. 2004-575 of June 21, 2004 on confidence in the digital economy, it is specified to users of the website <a href='https://pixfort.com'> pixfort.com</a> the identity of the various stakeholders in the context of its implementation and
										its follow-up:
									</p>
									<p>
										<strong>Owner</strong>: SARL pixfort, Share capital of €1000, VAT number: FR08913146585, address of the company's head office: 2 Rue charles baudelaire, 69002, Lyon.
										<br />
										<strong>Publication manager</strong>: pixfort SARL - support@pixfort.com
										<br />
										<strong>Webmaster</strong>: pixfort SARL - support@pixfort.com
										<br />
										<strong>Hosting</strong>: DigitalOcean, LLC - 101 6th Ave, New York, NY
										<br />
										<strong>Data Protection Officer</strong>: pixfort SARL - support@pixfort.com
										<br />
									</p>
									<h2>2. General conditions of use of the site and the offered services.</h2>
									<p>
										The Site constitutes an intellectual work protected by the provisions of the Intellectual Property Code and applicable International Regulations. The Customer may not in any way reuse, transfer or exploit for his own account all or part of the elements or
										works of the Site.
									</p>
									<p>
										Use of the site <a href='https://www.pixfort.com'>pixfort.com</a> implies full acceptance of the general conditions of use described below. These conditions of use may be modified or supplemented at any time. moment, users of the site{' '}
										<a href='https://www.pixfort.com/'>pixfort.com</a> are therefore invited to consult them on a regular basis.
									</p>
									<p>
										This website is normally accessible to users at any time. An interruption due to technical maintenance may however be decided by <a href='https://www.pixfort.com/'>pixfort.com</a>, who will then endeavor to communicate before users of the dates and times of
										the intervention. The website <a href='https://www.pixfort.com/'>pixfort.com</a> is updated regularly by <a href='https://www.pixfort.com/'> pixfort.com</a> responsible. Similarly, the references legal can be modified at any time: they nevertheless impose
										themselves on the user who is invited to refer to them as often as possible in order to become acquainted with them.
									</p>
									<h2>3. Description of the provided services.</h2>
									<p>
										The purpose of the website <a href='https://www.pixfort.com/'>pixfort.com</a> is to provide information concerning all of the company's activities. <a href='https://www.pixfort.com/'>pixfort.com</a> endeavors to provide on the site{' '}
										<a href='https://www.pixfort.com/'>pixfort.com</a> as accurate information as possible. However, it cannot be held responsible for omissions, inaccuracies and shortcomings in the update, whether caused by it or by the third party partners who provide it with
										this information.
									</p>
									<p>
										All the information indicated on the site <a href='https://www.pixfort.com/'>pixfort.com</a> is given for information only, and is subject to change. Furthermore, the information on the site <a href='https://www.pixfort.com/'>pixfort.com</a> are not
										exhaustive. They are given subject to modifications having been made since they were put online.
									</p>
									<h2>4. Contractual limitations on technical data.</h2>
									<p>
										The site uses JavaScript technology. The website cannot be held responsible for material damage related to the use of the site. In addition, the user of the site agrees to access the site using recent equipment, not containing viruses. and with an updated
										latest generation browser.
									</p>
									<p>
										The objective is to provide a service that ensures the best accessibility rate. The hosting provider ensures the continuity of its service 24 hours a day, every day of the year. It nevertheless reserves the right to interrupt the hosting service for the
										shortest possible durations, in particular for the purposes of maintenance, improvement of its infrastructures, failure of its infrastructures or if the Services and Services generate traffic deemed to be abnormal.
									</p>
									<p>
										<a href='https://www.pixfort.com/'>pixfort.com</a> and the hosting provider cannot be held responsible in the event of malfunction of the Internet network, telephone lines or computer equipment and telephony linked in particular to network congestion
										preventing access to the server.
									</p>
									<h2>5. Intellectual Property and Counterfeits.</h2>
									<p>
										<a href='https://www.pixfort.com/'>pixfort.com</a> is the owner of the intellectual property rights and holds the rights of use on all the elements accessible on the website, in particular the texts, images, graphics, logos, files, code, videos, icons and
										sounds. Any reproduction, representation, modification, publication, adaptation of all or part of the elements of the site, whatever the means or process used, is prohibited, except with the prior written authorization of{' '}
										<a href='https://www.pixfort.com/'>pixfort.com</a>.
									</p>
									<p>Any unauthorized use of the site or any of the elements it contains will be considered as constituting an infringement and prosecuted in accordance with the provisions of articles L.335-2 and following of the Intellectual Property Code.</p>
									<h2>6. Limitation of Liability.</h2>
									<p>
										<a href='https://www.pixfort.com/'>pixfort.com</a> acts as the publisher of the site. <a href='https://www.pixfort.com/'>pixfort.com</a> is responsible for the quality and veracity of the Content it publishes.
									</p>
									<p>
										<a href='https://www.pixfort.com/'>pixfort.com</a> cannot be held liable for any direct or indirect damage caused to the user's equipment when accessing the website <a href='https://www.pixfort.com/'>pixfort.com</a>, and resulting either from the use of
										equipment that does not meet the specifications indicated in point 4, either the appearance of a bug or an incompatibility.
									</p>
									<p>
										<a href='https://www.pixfort.com/'>pixfort.com</a> cannot also be held liable for consequential damages (such as loss of market or loss of opportunity) consecutive to the use of the site <a href='https://www.pixfort.com/'>pixfort.com</a>. Interactive spaces
										(possibility to ask questions in the contact space) are available to users. <a href='https://www.pixfort.com/'>pixfort.com</a> reserves the right to delete, without prior notice, any content deposited in this space which would contravene the legislation
										applicable in France, in particular the provisions relating to data protection. If applicable, <a href='https://www.pixfort.com/'>pixfort.com</a> also reserves the right to question the civil and/or criminal liability of the user, in particular in the event of
										a message racist, offensive, defamatory, or pornographic, whatever either the medium used (text, photograph, etc.).
									</p>
									<h2>7. Management of personal data.</h2>
									<p>
										The Customer is informed of the regulations concerning marketing communication, the law of June 21, 2014 for confidence in the Digital Economy, the Data Protection Act of August 06, 2004 as well as the General Data Protection Regulations. (GDPR: No. 2016-679).
									</p>
									<h3>7.1 Persons responsible for collecting personal data</h3>
									<p>For the Personal Data collected as part of the creation of the User's personal account and its navigation on the Site, the person responsible for processing Personal Data is: pixfort SARL - support@pixfort.com.</p>
									<p>
										As responsible for processing the data it collects, <a href='https://www.pixfort.com/'>pixfort.com</a> undertakes to comply with the framework of the legal provisions in force. In particular, it is up to the Customer to establish the purposes of its data
										processing, to provide its prospects and customers, from the collection of their consent, with complete information on the processing of their personal data and to maintain a register of processing operations in line with reality. Each whenever{' '}
										<a href='https://www.pixfort.com/'>pixfort.com</a> processes Personal Data, <a href='https://www.pixfort.com/'>pixfort. com</a> takes all reasonable steps to ensure the accuracy and relevance of Personal Data to the regard to the purposes for which{' '}
										<a href='https://www.pixfort.com/'>pixfort.com</a> processes them.
									</p>
									<h3>7.2 Purpose of the data collected</h3>
									<p>
										<a href='https://www.pixfort.com/'>pixfort.com</a> may process all or part of the data:
									</p>
									<ul>
										<li>to allow navigation on the Site and the management and traceability of the services ordered by the user: connection and use data for the Site, invoicing, order history, etc. </li>

										<li>to prevent and fight against computer fraud (spamming, hacking, etc.): computer equipment used for browsing, IP address, password (hashed). </li>

										<li>to improve navigation on the Site: connection and usage data. </li>

										<li>
											to conduct optional satisfaction surveys on <a href='https://www.pixfort.com/'>pixfort.com</a>: email address.
										</li>
										<li>to carry out communication campaigns (sms, email): telephone number, email address.</li>
									</ul>
									<p>
										<a href='https://www.pixfort.com/'>pixfort.com</a> does not market your personal data which is therefore only used by necessity or for statistical and analysis purposes.
									</p>
									<h3>7.3 Right of access, rectification and opposition</h3>
									<p>
										In accordance with current European regulations, Users of <a href='https://www.pixfort.com/'>pixfort.com</a> have the following rights:{' '}
									</p>
									<ul>
										<li>
											right of access (article 15 GDPR) and rectification (article 16 GDPR), updating, completeness of User data right of blocking or erasure of personal User data (article 17 GDPR), when they are inaccurate, incomplete, ambiguous, outdated, or whose collection,
											use, communication or storage is prohibited.
										</li>

										<li>right to withdraw consent at any time (article 13-2c GDPR).</li>

										<li>right to limit the processing of User data (Article 18 GDPR).</li>

										<li>right to oppose the processing of User data (article 21 GDPR).</li>

										<li>right to the portability of data that Users have provided, when this data is subject to automated processing based on their consent or on a contract (article 20 GDPR).</li>

										<li>
											right to define the fate of Users' data after their death and to choose to whom <a href='https://www.pixfort.com/'>pixfort.com</a> will have to communicate (or not) their data to a third party that they have previously designated.
										</li>
									</ul>
									<p>
										As soon as <a href='https://www.pixfort.com/'>pixfort.com</a> becomes aware of the death of a User and in the absence of instructions from him, <a href='https ://www.pixfort.com/'>pixfort.com</a> undertakes to destroy its data, unless their retention proves
										necessary for evidentiary purposes or to meet a legal obligation.
									</p>
									<p>
										If the User wishes to know how <a href='https://www.pixfort.com/'>pixfort.com</a> uses his Personal Data, ask to rectify them or oppose their processing, the User can contact <a href='https://www.pixfort.com/'>pixfort.com</a> by writing to the following
										address: support@pixfort.com
									</p>
									<p>
										In this case, the User must indicate the Personal Data that he would like <a href='https://www.pixfort.com/'>pixfort.com</a> to correct, update or delete, by identifying himself precisely with a copy of an identity document (identity card or passport).
									</p>
									<p>
										Requests to delete Personal Data will be subject to the obligations imposed on <a href='https://www.pixfort.com/'>pixfort.com</a> by law, in particular with regard to the retention or archiving of documents.
									</p>
									<h3>7.4 Non-disclosure of personal data</h3>
									<p>
										<a href='https://www.pixfort.com/'>pixfort.com</a> is prohibited from processing, hosting or transferring the Information collected on its Customers to a country recognized as “not adequate” by the European Commission without inform the customer in advance.
										However, <a href='https://www.pixfort.com/'>pixfort.com</a> remains free to choose its technical and commercial subcontractors on the condition that they present sufficient guarantees to the in view of the requirements of General Data Protection Regulation
										(GDPR: n° 2016-679).
									</p>
									<p>
										<a href='https://www.pixfort.com/'>pixfort.com</a> undertakes to take all necessary precautions to preserve the security of the Information and in particular that it is not communicated to unauthorized persons. However, if an incident affecting the integrity
										or confidentiality of Customer Information is brought to the attention of <a href='https://www.pixfort.com/'>pixfort.com</a>, it must as soon as possible inform the Customer and communicate to him the corrective measures taken. Furthermore,{' '}
										<a href='https://www.pixfort.com/'>pixfort.com</a> does not collect any “sensitive data”.
									</p>
									<p>
										The User's Personal Data may be processed by subsidiaries of <a href='https://www.pixfort.com/'>pixfort.com</a> and subcontractors (service providers), exclusively in order to achieve the purposes of this policy.
									</p>
									<p>
										Within the limits of their respective attributions and for the purposes mentioned above, the main persons likely to have access to the data of the Users of <a href='https://www.pixfort.com/'>pixfort.com</a> are mainly agents of our customer service.
									</p>
									<div>
										<h3>7.5 Types de données collectées</h3>
										<p>
											Concernant les utilisateurs d'un Site <a href='https://www.pixfort.com/'>pixfort.com</a>, nous collectons les données qui sont indispensables au fonctionnement du service&nbsp;, et qui seront conservées pendant une période maximale de 2 ans après la fin de
											la relation contractuelle.
											<br />
										</p>
									</div>
									<h2>8. Incident notification</h2>
									<p>
										No matter how hard you try, no method of transmission over the Internet, and no method of electronic storage, is completely secure. We cannot therefore guarantee absolute security. If we became aware of a breach security, we would notify affected users so that
										they can take appropriate action. Our incident notification procedures take into account our legal obligations, whether at national or European level. We we are committed to fully informing our Customers of all matters relating to the security of their account
										and to providing them with all the information necessary to help them comply with their own regulatory reporting obligations.
									</p>
									<p>
										No personal information of the user of the site <a href='https://www.pixfort.com/'>pixfort.com</a> is published without the knowledge of the user, exchanged, transferred, transferred or sold on any medium whatsoever to third parties. Only the hypothesis of the
										redemption of <a href='https://www.pixfort.com/'>pixfort.com</a> and its rights would allow the transmission of said information to the prospective purchaser who would in turn be bound by the same obligation to retention and modification of data with respect
										to the user of the site <a href='https://www.pixfort.com/'>pixfort.com</a>.
									</p>
									<h3>Security</h3>
									<p>
										To ensure the security and confidentiality of Personal Data and Personal Health Data, <a href='https://www.pixfort.com/'>pixfort.com</a> uses networks protected by standard devices such as only by firewall, the pseudonymization, encryption and password.{' '}
									</p>
									<p>
										When processing Personal Data, <a href='https://www.pixfort.com/'>pixfort.com</a> takes all reasonable measures to protect it against loss, misuse, unauthorized access , disclosure, alteration or destruction.
									</p>
									<h2>9. Hypertext links “cookies” and internet tags (“tags”)</h2>
									<p>
										The site <a href='https://www.pixfort.com/'>pixfort.com</a> contains a certain number of hypertext links to other sites, set up with the authorization of <a href='https://www.pixfort.com/'>pixfort.com</a>. However,{' '}
										<a href='https://www.pixfort.com/'>pixfort.com</a> does not have the possibility of verifying the content of the sites thus visited, and therefore assumes no responsibility for this fact .
									</p>
									Unless you decide to disable cookies, you agree that the site can use them. You can deactivate these cookies at any time and free of charge from the deactivation options offered to you and recalled below, knowing that this may reduce or prevent accessibility to
									all or part of the Services offered by the site.
									<h3>9.1. “COOKIES”</h3>
									<p>
										A "cookie" is a small information file sent to the User's browser and saved within the User's terminal (eg computer, smartphone), (hereinafter "Cookies"). This file includes information such as the name of domain of the User, the Internet access provider of
										the User, the operating system of the User, as well as the date and time of access. Cookies do not in any way risk damaging the User's terminal.
									</p>
									<p>
										<a href='https://www.pixfort.com/'>pixfort.com</a> is likely to process the User's information concerning his visit to the Site, such as the pages consulted, the searches carried out. This information allows <a href='https://www.pixfort.com/'>pixfort.com</a>{' '}
										to improve the content of the Site, the navigation of the User.
									</p>
									<p>
										Cookies facilitating navigation and / or the provision of services offered by the Site, the User can configure his browser to allow him to decide whether or not he wishes to accept them so that Cookies are saved. in the terminal or, on the contrary, that they
										are rejected, either systematically or according to their issuer. The User can also configure his navigation software so that the acceptance or refusal of Cookies is offered to him. punctually, before a Cookie is likely to be saved in his terminal.{' '}
										<a href='https://www.pixfort.com/'>pixfort.com</a> informs the User that, in this case, the functions of their browser software may not be all available.
									</p>
									<p>
										If the User refuses the registration of Cookies in his terminal or his browser, or if the User deletes those which are registered there, the User is informed that his navigation and his experience on the Site may be limited. It could also be the case when{' '}
										<a href='https://www.pixfort.com/'>pixfort.com</a> or one of its service providers cannot recognize, for technical compatibility purposes, the type browser used by the terminal, language and display settings or the country from which the terminal appears to be
										connected to the Internet.
									</p>
									<p>
										If applicable, <a href='https://www.pixfort.com/'>pixfort.com</a> declines all responsibility for the consequences linked to the degraded functioning of the Site and the services possibly offered by <a href='https://www.pixfort.com/'>pixfort.com</a>, resulting
										from (i) the refusal of Cookies by the User (ii) the impossibility for <a href='https://www.pixfort.com/'>pixfort.com</a> to record or consult Cookies necessary for their operation due to the choice of the User. For management Cookies and User choices, the
										configuration of each browser is different. It is described in the help menu of the browser, which will allow you to know how the User can modify his wishes in terms of Cookies.
									</p>
									<p>
										At any time, the User can choose to express and modify his wishes in terms of Cookies. <a href='https://www.pixfort.com/'>pixfort.com</a> may also use the services of external service providers to help it collect and process the information described in this
										section.
									</p>
									{/* <p>
										Finally, by clicking on the icons dedicated to the social networks Twitter, Facebook, Linkedin and pixfort Plus appearing on the Site of <a href='https://www.pixfort.com/'>pixfort.com</a> or in its mobile application and if the User has accepted the deposit of
										cookies by continuing to browse the <a href='https://www.pixfort.com/'>pixfort.com</a> Website or mobile application, Twitter, Facebook, Linkedin and pixfort Plus may also deposit cookies on your terminals (computer, tablet, cellphone).
									</p>
									<p>
										These types of cookies are only placed on your terminals if you consent to them, by continuing to browse the Website or the mobile application of <a href='https://www.pixfort.com/'> pixfort.com</a>. At any time, the User can nevertheless withdraw its consent
										to <a href='https://www.pixfort.com/'>pixfort.com</a> depositing this type of cookie.
									</p> */}
									<h3>Section 9.2. INTERNET TAGS</h3>
									<p>
										<a href='https://www.pixfort.com/'>pixfort.com</a> may occasionally use web beacons (also known as "tags", or action tags, single-pixel GIFs, clear GIFs , invisible GIFs and one-to-one GIFs) and deploy them through a partner specializing in web analytics
										likely to be located (and therefore store the corresponding information, including the User's IP address) in a foreign country.
									</p>
									<p>These tags are placed both in online advertisements allowing Internet users to access the Site, and on the various pages thereof.</p>
									<p>
										This technology allows <a href='https://www.pixfort.com/'>pixfort.com</a> to evaluate visitors' responses to the Site and the effectiveness of its actions (for example, the number of times a page is opened and the information consulted), as well as the use of
										this Site by the User.{' '}
									</p>
									<p>
										The external service provider may possibly collect information on visitors to the Site and other Internet sites using these tags, compile reports on the activity of the Site for the attention of <a href='https://www.pixfort.com/'>pixfort.com</a>, and provide
										other services relating to the use of it and the Internet.
									</p>
									<p></p>
									<h2>10. Applicable law and attribution of jurisdiction.</h2>
									<p>
										Any dispute in connection with the use of the site <a href='https://www.pixfort.com/'>pixfort.com</a> is subject to the French law.
									</p>
								</Tab.Panel>
							</Tab.Panels>
						</Tab.Group>
					</div>
				</main>
			</div>
		</Layout>
	);
};

export default Legal;
